import React, { useEffect } from "react"
import SEO from "../../components/seo/seo"
import HeaderBackground from "../../page-components/rewards-components/header-background"
import AnimateTexts from "../../components/animations/animate-texts"
import FormButton from "../../components/form-components/form-button"
import AnimateFade from "../../components/animations/animate-fade"
import InlineLink from "../../components/general/inline-link"
import CustomContainer from "../../components/general/contianer"
import FAQ from "../../page-components/faq/faq.json";
import { Disclosure } from "@headlessui/react";
import Footer from "../../components/footer/footer"
import { FiCheck } from "@react-icons/all-files/fi/FiCheck"
import { FiShoppingCart } from "@react-icons/all-files/fi/FiShoppingCart"
import { FiStar } from "@react-icons/all-files/fi/FiStar"
import { FiInstagram } from "@react-icons/all-files/fi/FiInstagram"
import { VscAccount } from "@react-icons/all-files/vsc/VscAccount"
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown"
import AnimateScale from "../../components/animations/animate-scale"
import Vegan from "../../images/promises/vegan.svg"
import CrueltyFree from "../../images/promises/cruelty_free.svg"
import SkinType from "../../images/promises/all_skintype.svg"
import ChemicalsFree from "../../images/promises/checimals.svg"
import SmallBatches from "../../images/promises/small_batches.svg"
import Dermatologist from "../../images/promises/derma.svg"
import gsap from "gsap"
import { imageKitLink } from "../../utils/fomatters"

let promises = [
    {
        image: Vegan,
        alt: "100% Vegan",
        caption: "100% Vegan"
    },
    {
        image: CrueltyFree,
        alt: "Cruelty Free",
        caption: "Cruelty Free"
    },
    {
        image: SkinType,
        alt: "For All Skin Types",
        caption: "For All Skin Types"
    },
    {
        image: ChemicalsFree,
        alt: "No Parabens & Sulphates",
        caption: "No Parabens & Sulphates"
    },
    {
        image: SmallBatches,
        alt: "Crafted in small batches",
        caption: "Crafted in small batches"
    },
    {
        image: Dermatologist,
        alt: "Dermatologist Approved",
        caption: "Dermatologist Approved"
    }
]

const GroomdCorporateGifting = ({transitionStatus}) => {

    // Image Scroll
    useEffect(() => {

        let section = document.querySelector(`.corporate-about-section`);
        let img: HTMLDivElement = section.querySelector(`.corporate_about_main_image`);

        gsap.to(img, {
            y: 120,
            ease: "none",
            scrollTrigger: {
                trigger: section,
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            }
        });
    }, [])

    return (
        <>
            <SEO description={"With no minimums, super fast lead times and personalisation available, we’re the perfect choice for a quirky, unique corporate gifts."} title={"Corporate Gifting"} pathname={"/corporate-gifting"}/>
            <HeaderBackground transitionStatus={transitionStatus} img={imageKitLink('/static-images/corporate_gifting_groomd.png')} className={"groomd-rewards-image"}>
                <div className={"flex flex-col justify-center items-center py-36 pb-48 sm:py-40 sm:pb-96 2xl:py-48 2xl:pb-96"}>
                    <AnimateTexts className={`corporate_gifting_heading`} transitionStatus={transitionStatus} delay={500}>
                        <div className={"max-w-2xl 2xl:max-w-4xl"}>
                            <p className={"text-gray-800 font-semibold font-sans uppercase tracking-widest text-md text-center mb-2"} data-text-animate={true}>
                                CORPORATE GIFTING
                            </p>
                            <h1 className={"text-gray-800 font-bold font-serif text-3xl md:text-5xl 2xl:text-6xl text-center px-4"} data-text-animate={true}>
                                We’re the perfect choice for a quirky, unique corporate gifts!
                            </h1>
                        </div>
                    </AnimateTexts>
                    <AnimateFade className={"corporate_gifting_heading_button"} rootClass={"mt-12"} transitionStatus={transitionStatus} delay={550}>
                        <div data-animate-fade={true}>
                            <FormButton to={"mailto:care@groomd.co.in"} disableArrowAnimation>
                                Get in Touch
                            </FormButton>
                        </div>
                    </AnimateFade>
                </div>
            </HeaderBackground>
            <section className={"corporate-about-section overflow-hidden relative py-20 sm:py-40"} style={{ borderBottom: "0.5px solid rgba(100,50,50,0.2)" }}>
                <div className={`c-home-intro_circle -big`} style={{ zIndex: -1 }}/>
                <div className={`c-home-intro_circle -small`} style={{ zIndex: -1 }} />
                <CustomContainer>
                    <AnimateTexts className={`corporate_about_text`} transitionStatus={transitionStatus} delay={200}>
                        <h2 className={"text-gray-500 font-semibold font-sans uppercase tracking-widest text-sm sm:text-base mb-2 px-4 sm:px-0"} data-text-animate={true}>
                            ABOUT GROOMD
                        </h2>
                        <p className={"text-gray-800 font-bold font-serif text-xl md:text-3xl leading-tight max-w-2xl px-4 sm:px-0"} data-text-animate={true}>
                            Groomd standards are uncompromising. We are a 100% vegan and cruelty-free brand that is developed with a belief that grooming
                            for men should be easy, effortless, and natural.
                        </p>
                        <p className={"text-gray-800 font-sans text-base mt-3 leading-loose max-w-2xl px-4 sm:px-0"} data-text-animate={true}>
                            We offer a head to toe range of personal care
                            essentials. Our entire collection of products is SLS and Paraben free. We make sure that no harm is caused to any animals during the making and testing of our products. Our products are
                            considerate towards the planet and more suitable for your wellness.
                        </p>
                    </AnimateTexts>
                </CustomContainer>
                <CustomContainer className={"relative flex items-center mt-8 md:mt-16"}>
                    <AnimateFade className={"corporate_about_icons"} rootClass={"max-w-5xl grid grid-cols-2 md:grid-cols-3 gap-6 px-4 md:px-0"} transitionStatus={transitionStatus} delay={300}>
                        {
                            promises.map((promise) => {
                                return <div key={promise.alt} className={"flex items-center"} data-animate-fade={true}>
                                    <img loading={"lazy"} src={promise.image} alt={promise.alt} className={'w-4 md:w-5'}/>
                                    <p className={"font-sans text-sm md:text-base pl-4 pr-4 md:pr-10"}>
                                        {promise.caption}
                                    </p>
                                </div>

                            })
                        }
                    </AnimateFade>
                </CustomContainer>
                <CustomContainer className={"relative flex mt-8 md:mt-16"}>
                    <AnimateFade className={"promises_button"} transitionStatus={transitionStatus} delay={300}>
                        <div data-animate-fade={true}>
                            <FormButton to={"/about"} style={{ zIndex: 1 }} disableArrowAnimation >
                                About Us
                            </FormButton>
                        </div>
                    </AnimateFade>
                </CustomContainer>
                <div className={"h-44 sm:h-0"}/>
                <AnimateFade className={"corporate_about_main_image"} rootClass={"absolute right-0 bottom-0 w-full sm:1/2 h-4/5"} transitionStatus={transitionStatus} delay={300}>
                    <div className={"w-full h-full"} style={{ background: `url(${imageKitLink('/static-images/groomd_about.png')}) right bottom / contain no-repeat` }}/>
                </AnimateFade>
            </section>
            <section className={"corporate-collection-section overflow-hidden relative py-20 sm:py-40"} style={{ borderBottom: "0.5px solid rgba(100,50,50,0.2)" }}>
                <CustomContainer>
                    <div className={"grid grid-cols-1 sm:grid-cols-2"}>
                        <AnimateTexts className={`corporate_collection_text`} transitionStatus={transitionStatus} delay={200}>
                            <h2 className={"text-gray-500 font-semibold font-sans uppercase tracking-widest text-sm sm:text-base mb-2 px-4 sm:px-0"} data-text-animate={true}>
                                WHAT WE OFFER
                            </h2>
                            <p className={"text-gray-800 font-bold font-serif text-xl md:text-3xl leading-tight max-w-2xl px-4 sm:px-0"} >
                                <span data-text-animate={true}>Our collection focuses on</span>
                                <span data-text-animate={true} className={"underline"}>personal care.</span>
                            </p>
                            <p className={"text-gray-800 font-sans text-base mt-3 leading-loose max-w-2xl px-4 sm:px-0"} data-text-animate={true}>
                                Our collection includes
                                multifunctional skincare products
                                to support our customers in delivering the best version of themselves.
                            </p>
                            <p className={"text-gray-800 font-sans text-base mt-3 leading-loose max-w-2xl px-4 sm:px-0 mt-3"} data-text-animate={true}>
                                We have products that are not biased on the basis of gender. Groomd has ensured to incorporate all such products in our corporate gifting kits suitable for both men and women.
                            </p>
                        </AnimateTexts>
                        <div className={"grid grid-cols-3 gap-0 sm:gap-6"}>
                            {
                                [
                                    {
                                        img: imageKitLink('/static-images/groomd_about.png'),
                                        text: "Holiday/Travel Sets"
                                    },
                                    {
                                        img: imageKitLink('/static-images/festive.png'),
                                        text: "Body Care"
                                    },
                                    {
                                        img: imageKitLink('/static-images/body.png'),
                                        text: "Face Care"
                                    },
                                    {
                                        img: imageKitLink('/static-images/face.png'),
                                        text: "Hair Care"
                                    },
                                    {
                                        img: imageKitLink('/static-images/hair.png'),
                                        text: "Hand Creams"
                                    },
                                    {
                                        img: imageKitLink('/static-images/hand_creams.png'),
                                        text: "Hand/Sanitary Care"
                                    },
                                    {
                                        img: imageKitLink('/static-images/hand.png'),
                                        text: "Custom Soaps"
                                    },
                                    {
                                        img: imageKitLink('/static-images/soaps.png'),
                                        text: "Gift Cards"
                                    },
                                    {
                                        img: imageKitLink('/static-images/gift_cards.png'),
                                        text: "Summer Essentials"
                                    }
                                ].map((img) => {
                                    return <div className={"w-full flex justify-center flex-col p-8"}>
                                        <img loading={"lazy"} src={img.img} alt={""} className={'w-full'}/>
                                        <div className={"flex justify-center w-full text-sans text-sm mt-3 text-center"}>
                                            <div>{img.text}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </CustomContainer>
            </section>
            <Footer transitionStatus={transitionStatus}/>
        </>
    )

}

export default GroomdCorporateGifting;